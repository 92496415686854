import React,{ Suspense,lazy} from "react";
import { Switch, Route } from "react-router-dom"

const Header = lazy(()=> import('./header'));
const Main = lazy(()=> import('./main'));
const Footer = lazy(()=> import('./footer'));



export default function App() {
    const loading = <div style={{height:'100%', width:'100%', display:'flex', justifyContent:"center", alignItems:'center'}}><p>Loading...</p></div>
    return (
        <Suspense fallback={loading}>
            <Header />
            <Switch>
                <Route exact path="/" component={Main}></Route>
                {/* <Route path="/about" component={About}></Route>
                <Route path="/contact" component={Contact}></Route> */}
            </Switch>

            <Footer />
        </Suspense>
    )
}